/* eslint-disable react/no-array-index-key */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import Layout from 'components/layout/Layout'
import NavLink from 'components/shared/NavLink'

// Third Party
import styled from 'styled-components'
import Levenshtein from 'js-levenshtein'
import { getLanguage } from 'services/language'

const NotFound = styled.div`
  & h1 {
    font-size: 100px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-family: ${({ theme }) => theme.font.family.secondary};
    color: ${({ theme }) => theme.color.text.secondary};
  }

  & h3 {
    font-weight: ${({ theme }) => theme.font.weight.bold};
    color: ${({ theme }) => theme.color.text.primaryLight};
  }

  & p {
    color: ${({ theme }) => theme.color.text.primaryLight};

    & b,
    strong {
      color: ${({ theme }) => theme.color.secondary};
    }
  }

  & a {
    color: ${({ theme }) => theme.color.text.primaryLight};

    & b,
    strong {
      font-size: 16px;
      line-height: 16px;
      font-family: ${({ theme }) => theme.font.family.secondary};
      color: ${({ theme }) => theme.color.secondary};
      padding-right: 5px;
    }

    &:hover {
      text-decoration: underline;
    }
  }
`

const Block = styled.div`
  background-color: ${({ theme }) => theme.color.light};
  border: 3px solid ${({ theme }) => theme.color.border.primaryLight};
  border-radius: 10px;
  padding: 20px 20px 20px 20px;
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
`

const Links = styled.div`
  & p {
    font-size: 14px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }
`

const BackToHome = styled.div`
  & a {
    text-decoration: underline;
    font-size: 18px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    transition: all 0.3s ease;

    &:hover {
      color: ${({ theme }) => theme.color.secondary};
    }
  }
`

const Or = styled.div`
  padding: 10px 0;
  color: ${({ theme }) => theme.color.secondary};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`

interface Props {
  location: any
}

const WhiteBlock = styled.div`
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  border-radius: 25px;
  padding: 10px 30px;
  background-color: ${({ theme }) => theme.color.light};
`

const BannerWrapper = styled.div`
  position: relative;
  height: fit-content;
  padding: 120px 0 10px 0;
  background: linear-gradient(277.87deg, #09154f 26.05%, #1402ac 96.86%);
`

const Styled404 = styled.section`
  background-color: ${({ theme }) => theme.color.light};
`

const NotFoundPage: React.FC<Props> = ({ location }) => {
  const query = useStaticQuery<GatsbyTypes.notfoundQuery>(graphql`
    query notfound {
      allWpPage {
        nodes {
          title
          uri
        }
      }
    }
  `)
  const language = getLanguage()

  const [list, setList] = React.useState([])

  React.useEffect(() => {
    const levenshteinList: any = []

    query.allWpPage.nodes.forEach((n: any) => {
      levenshteinList.push({
        uri: n.uri,
        title: n.title,
        score: Levenshtein(location.pathname, n.uri),
      })
    })

    setList(levenshteinList)
  }, [])

  return (
    <Layout>
      <Styled404>
        <BannerWrapper>
          <div className="container pt-lg-5 pt-3">
            <NotFound className="row justify-content-center">
              <div className="col-lg-8 pb-lg-5 pb-3">
                <WhiteBlock>
                  <h1>Oops!</h1>
                  <h3>
                    {language === 'nl_NL'
                      ? `Het lijkt erop dat deze pagina niet (meer) bestaat.`
                      : `It seems this page does not exist (anymore).`}
                  </h3>
                  <div className="py-3">
                    <p className="mb-0">
                      Error code: <b>404</b>
                    </p>
                  </div>
                </WhiteBlock>
              </div>
            </NotFound>
          </div>
        </BannerWrapper>
        <div className="container py-lg-5 py-3">
          <NotFound className="row justify-content-center">
            <Links className="col-lg-9">
              <BackToHome>
                <NavLink to="/">
                  {language === 'nl_NL'
                    ? `Ga terug naar de homepage`
                    : `Go back to the homepage`}
                </NavLink>
              </BackToHome>
              <Or>{language === 'nl_NL' ? `OF` : `OR`}</Or>
              <p className="mb-0">
                {language === 'nl_NL'
                  ? `hier zijn een paar paginas die misschien op jouw pagina lijken:`
                  : `here are some pages that might be similar to the one your are looking for:`}
              </p>
              <Block>
                {list
                  .sort((a: any, b: any) => a.score - b.score)
                  .map((n: any, index: number) => (
                    <div key={index}>
                      <NavLink to={n.uri}>
                        <b>{`>`}</b>
                        {n.title}
                      </NavLink>
                    </div>
                  ))}
              </Block>
            </Links>
          </NotFound>
        </div>
      </Styled404>
    </Layout>
  )
}

export default NotFoundPage
